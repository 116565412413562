// status is copy, link or reject
export const approveTaskPublish = async (taskId, workspaceId, status, recursive) => {
  recursive = recursive || true;
  status = status || "reject";

  let action_string = "";
  let human_friendly_message = "";
  if (status == "reject") {
    action_string = "rejectTaskSubmissionToWorkspace";
    human_friendly_message = `Rejected submission of task ${taskId} into workspace ${workspaceId}`
  } else if (status == "link") {
    action_string = "approveLinkTaskSubmissionToWorkspace";
    human_friendly_message = `Approve submission of task ${taskId} into workspace ${workspaceId} - link, recursive: ${recursive}`;
  } else if (status == "copy") {
    action_string = "approveCopyTaskSubmissionToWorkspace";
    human_friendly_message = `Approve submission of task ${taskId} into workspace ${workspaceId} - copy, recursive: ${recursive}`;
  }

  await logUserActivity({
    "pageURL": getCurrentURL(),
    "human_friendly_message": human_friendly_message,
    "action": action_string,
    "resource": `/tasks/${taskId}`,
    "referrer": document.referrer
  }, true)

  return await logFetchAJAX(getUrl(`/api/tasks/${taskId}/actions:approve_publish`), {
    method: "POST",
    body: {
      "workspace_id": workspaceId,
      "recursive": recursive,
      "status": status,
    }
  })
}
