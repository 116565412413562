<template>
    <!--components/TopCopilotBtn.vue-->
    <button class="btn guided-tour-btn prevent_vertical_expansion" :class="{'btn-outline-primary': true}" @click.prevent.stop="top_copilot_btn_clicked" :disabled="! page_mounted_state">Agent</button>
</template>

<script setup>
const dayjs = useDayjs();
const page_mounted_state = usePageMountedState();

const top_copilot_btn_clicked = async (event) => {
    const now = dayjs();
    await showWaitPage();
    let parent_task = getEmptyTask();
    let full_name = await getCurrentUserFullName();
    parent_task.title = `AI agent session ${now.format()} by ${full_name}`;
    parent_task.description = `<p class="hideMe">AI agent task</p>`; // What is converting these special characters into unicode?  But this still work, and this string is not displayed in the UI.
    parent_task = await createTask(parent_task, {});

    hideWaitPage();

    let url = `/tasks/${parent_task.id}?agent=1`;
    await navigateToWrapper(url, {'open': {'target': '_blank'}});
}
</script>

<style scoped>
.prevent_vertical_expansion {
    align-self: center;
}

.guided-tour-btn:hover {
    border: 1px solid blue;
}
</style>
