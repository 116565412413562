<template>
  <!--components/UserIconTopRight.vue-->
  <div id="user_icon_container" v-show="isAuthenticated()">

    <span v-if="initials.length === 1" ref="userMenuIconRef" class="signed_in_user_icon"  @click="openUserMenu">
      {{ initials }}
    </span>
    <span v-else ref="userMenuIconRef" class="signed_in_users_icon"  @click="openUserMenu">
      {{ initials }}
    </span>

    <ul ref="userMenuRef" id="user_menu" class="list-unstyled" :class="{'user-menu-active': showUserMenu}">
      <li class="username_org_info">
        <table>
          <tbody>
            <tr>
              <td class="user_icon_container_td">
                <span class="signed_in_users_icon" >{{ initials }}</span>
              </td>
              <td>
                <div class="user_icon_menu_full_name">{{ full_name }}</div>
                <div v-show="false">
                  {{ email }}
                </div>
                <div v-show="signed_in_org.length > 0">
                  <span >
                    {{ signed_in_org }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </li>

      <li @mousedown.prevent.stop="hideUserMenu(); dkNavigateTo('/edit-user-profile')" class="text-nowrap clickable">Update Profile</li>
      <li @mousedown.prevent.stop="hideUserMenu(); dkNavigateTo('/n/user-preferences')" class="text-nowrap clickable">Preferences</li>
      <li @mousedown.prevent.stop="hideUserMenu(); dkNavigateTo('/change-password')" class="text-nowrap clickable">Change Password</li>
      <li @mousedown.prevent.stop="hideUserMenu(); dkNavigateTo('/my-runbooks')" class="text-nowrap clickable">My runbooks</li>
      <li @mousedown.prevent.stop="hideUserMenu(); dkNavigateTo('/shared-with-me')" class="text-nowrap clickable">Shared with me</li>
      <li @mousedown.prevent.stop="logout" class="text-nowrap clickable">Sign out</li>
    </ul>

  </div>
</template>

<script setup>
const showUserMenu = ref(false)
let userMenuRef = ref(null)
let userMenuIconRef = ref(null)

const userInfoStore = useUserInfoStore();
let user_info = userInfoStore.getUserInfo;

let enforce_login = await getEnforceLogin()
/*
I do not quite know why sometime we see the user icon without the initial, but in such a 
case, I think the user somehow become unauthenticated, and therefore, this code try to 
validate this theory, and if we still cannot get the current user information (the user 
is indeed not authenticated, or there is some issue on the server side), take the user 
to the login page and ask the user to sign-in again.  But we cannot force the user to 
the /vlogin page for environment like community because this logic does not work for 
community (users on community does not need to sign-in to see the runbook).  Need to 
have better logic.
*/
/*
if (! isAuthenticated()) {
  user_info = await getCurrentUserInfo();
  userInfoStore.setUserInfo(user_info);
  if (! isAuthenticated()) {
    if (typeof(window) != 'undefined') {
      window.location.href = '/vlogin'
    }
  }
}
*/

const getUserFullName = () => {
  const firstName = localStorage.getItem('first_name') || '';
  const lastName = localStorage.getItem('last_name') || '';
  let full_name = [firstName, lastName].filter(name => name.trim() !== '').join(' ');
  return full_name;
}

const getDisplayOrg = () => {
  let org = localStorage.getItem('org');
  if (org == null) {
    return '';
  } else {
    if (org.toLowerCase() == 'dagknows') {
      // For now, if the org is dagknows, we will display it.  If the org is dagknows, and if we do not want to display it,
      // we can change it here later.  The reason for this concern is that sometimes when we give images for client to try 
      // out, we left the SUPER_USER_ORG environment default to 'dagknows'.  In my opinion, we should not leave the 
      // SUPER_USER_ORG environment variable and the DEFAULT_ORG environment default to 'dagknows'
      return org;
    } else {
      if (org.toLowerCase().startsWith('trial')) {
        return org;
      } else {
        return org;
      }
    }
  }
}

const getInitials = () => {
  const firstName = user_info.first_name;
  const lastName = user_info.last_name; 
  let names = [firstName, lastName].filter(name => name.trim() !== '').join(' ');

  names = names.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials
}

let initials = '';
let full_name = '';
let signed_in_org = '';
let email = '';

if (isAuthenticated()) {
  initials = getInitials();
}

if (typeof(window) != 'undefined') {
  full_name = getUserFullName();
  email = localStorage.getItem('email');
  signed_in_org = getDisplayOrg();
}

async function logout() {
  await logUserActivity({
    "pageURL": getCurrentURL(),
    "human_friendly_message": "User signed out",
    "action": "signout",
    "old_value": getCurrentURL(),
    "new_value": "/",
    "resource": "",
    "referrer": document.referrer
  }, true);

  await signOut();
  showUserMenu.value = false;

  dkAlert("You have successfully signed-out.", function(){
    window.location.href = "/";
  })
  /*
  alert("You have successfully signed-out.");
  window.location.href = "/";
  */
}

const openUserMenu = () => {
  showUserMenu.value = true
}

const hideUserMenu = () => {
  showUserMenu.value = false
}

const handleClickOutside = (event) => {
  if (userMenuRef.value !== null) {
    if (!userMenuRef.value.contains(event.target) && !userMenuIconRef.value.contains(event.target)) {
      showUserMenu.value = false
    }
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<style scoped>
#user_icon_container {
  position: relative;
  align-self: center;
  margin-right: 15px;
}
#user_menu {
  display: none;
  margin-left: 0px;
  margin-top: 5px;
  box-shadow: var(--ds-shadow-overlay, 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31));
}
#user_menu li {
  padding: 5px 10px;
  display: block;
  text-align: left;
  margin-left: 0px;
}
#user_menu li:before {
  display: none;
}
#user_menu li a {
  text-decoration: none;
  color: black;
}

#user_menu li:hover {
  background-color: #eee;
}

#user_menu li.username_org_info:hover {
  background-color: transparent;
}

.signed_in_users_icon{
  font-size: 13px;
  cursor: pointer;
  /* border: 1px solid #ccc; */
  border-radius: 50%;
  padding: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: #0391e0;
  color: white;  
  display: inline-block;
  width: 30px;
  text-align: center;
}

.user-menu-active {
  display: block !important;
  position: absolute;
  top: 30px;
  right: 0px;
  background-color: white;
  border: 1px solid #eee;
  z-index: 1000;
}
.username_org_info {
  border-bottom: 1px solid #ccc;
}

.username_org_info table {
  width: 100%;
}
.user_icon_container_td {
  width: 40px;
}
.user_icon_menu_full_name {
  white-space: nowrap;
  max-width: 200px;
  overflow-x: hidden;
}
</style>

