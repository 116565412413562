export const dkConfirm = async (msg, ok_fn, cancel_fn) => {
    let template_str = `
    <div class="modal modal-md" tabindex="-1" aria-labelledby="Alert Modal Dialog" aria-hidden="true" id="dagknows_alert_confirm_dialog">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content collaboration-modal">
                <h5 class="modal-title"></h5>
                <div class="text-end modal-close">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <p style="text-align: center">${msg}</p>
                    <div style="text-align: center">
                        <button class="btn btn-primary ok-btn">OK</button>
                        <button class="btn btn-secondary cancel-btn">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `;
    hideTooltips(document.getElementsByTagName('body')[0]);
    if (typeof ($) == 'undefined') {
        await loadScript('/vstatic/zepto-1.2.0/zepto.min.js');      
    }

    $(template_str).appendTo('body');
    let modal_element = document.querySelector('#dagknows_alert_confirm_dialog');

    var myModal = new bootstrap.Modal(modal_element, { backdrop: false });
    myModal.show();
    let which_button = '';

    modal_element.querySelector('.cancel-btn').addEventListener('click', function (event) {
        which_button = 'cancel';
        myModal.hide();
        myModal.dispose();
    });

    modal_element.querySelector('.ok-btn').addEventListener('click', function (event) {
        which_button = 'ok';
        myModal.hide();
        myModal.dispose();
    })

    modal_element.addEventListener('hidden.bs.modal', function (event) {
        $('#dagknows_alert_confirm_dialog').remove();
        if (which_button == 'ok') {
            if (typeof(ok_fn) != 'undefined') {
                ok_fn();
            }
        } else {
            // If the user clicks on the x button, or press the escape key, the value for which_button should be '', 
            // and that is treated the same as 'cancel'
            if (typeof(cancel_fn) != 'undefined') {
                cancel_fn();
            }
        }
    });

}