export const syncTask = async (remote_task_id, remote_task_url, alltasks, taskCache) => {
    const body = {
      "source_info": {
        "source_url": remote_task_url,
        "source_id": remote_task_id,
      }, 
    }
    if (alltasks) {
      body.descendants = alltasks || null
    }
 
    const results = await logFetchAJAX(getUrl(`/api/tasks/sync/`), {
        method: 'POST',
        body: body
    });

    var alltasks = results.descendants || {};
    for (const taskid in alltasks) {
      taskCache[taskid] = alltasks[taskid]
    }

    for (const taskid in alltasks) {
      hydrateTask(alltasks[taskid], taskCache)
    }

    hydrateTask(results["task"], taskCache)

    logUserActivity({
      "pageURL": getCurrentURL(),
      "human_friendly_message": `Download task ${results["task"].id} - ${ results["task"].title } from ${remote_task_url.replace('/api','') + remote_task_id}`,
      "action": "syncTask",
      "resource": `/tasks/${results["task"].id}`,
      "referrer": document.referrer
    });

    return results["task"]
}
