export const createTask = async (task, taskCache) => {
    if (! task.script) {
        delete task.script;
    }
    if ((task.full_command || "") != (task.commands || []).join("\n")) {
      task.commands = task.full_command.split("\n")
    }

    let wsid = getWorkspaceFromDropdown();

    if ((wsid || "").trim().length >= 0) {
      task.workspace_ids = [wsid]
    }

    const result_task = await logFetchAJAX(getUrl('/api/tasks/'), {
        method: 'POST',
        body: {
          "task": task
        }
    });

    const newtask = result_task["task"]
    taskCache[newtask["id"]] = newtask

    await logUserActivity({
      "pageURL": getCurrentURL(),
      "human_friendly_message": `Created task ${newtask["id"]} - ${newtask["title"]}`,
      "action": "createTask",
      "new_value": JSON.stringify(removeFields(newtask)),
      "resource": `/tasks/${newtask["id"]}`,
      "referrer": document.referrer
    }, true)
  
    return newtask
}
