export const logUserActivity = async (payload, use_await) => {
    /*
    Things that the frontend should provide:
    "pageURL": { "type": "keyword", "index": False },
    "human_friendly_message": { "type": "keyword", "index": False },
    "action": { "type": "keyword", "index": False }, # A short string, no space allowed, describing the particular action being performed
    "old_value": { "type": "text", "index": False },
    "new_value": { "type": "text", "index": False },
    "resource": { "type": "keyword", "index": False },
    "referrer": { "type": "keyword", "index": False },

    "jobURL": { "type": "keyword", "index": False },
    */

    if ((typeof(window) != 'undefined') && (isAuthenticated())) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        payload.timezone = timezone;

        ['referrer','old_value','new_value','pageURL'].forEach((element) => {
            if ((payload.hasOwnProperty(element)) && (typeof(payload[element]) != 'undefined') && ((typeof(payload[element])).toLowerCase() == 'string') && (payload[element].includes('vLoginRedirect'))) {
                payload[element] = payload[element].replace("vLoginRedirect?p=/","")
            }
        })

        if (payload.action == "visitPage") {
            delete payload['new_value'];
            delete payload['old_value'];
        }

        let url = "/api/tasks/admin/logUserActivity"
        if (typeof(use_await) == 'undefined') {
            use_await = false;
        }

        if (use_await) {
            const result = await logFetchAJAX(getUrl(url), {
                method: 'POST',
                body: payload
            });    
        } else {
            const result = logFetchAJAX(getUrl(url), {
                method: 'POST',
                body: payload
            });    
        }
    }
}