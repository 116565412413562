export const removeFields = (existing_task) => {
    /*
    This composable takes a task object, create a copy of it, remove certain fields from it and returns the copy.
    This is used for tracking user activity.  For example, when the user edit a task, the delta that we mainly need 
    to capture is in the title, description, code, etc.  We do not want to capture the other fields, because this 
    can mean a lot of disk space being wasted, especially, if the task includes sub-tasks, and those vector strings.
    */

    let task = cloneTask(existing_task);
    ['approved_permissions','conv_id','created_at','creator','description_type','metadata','num_voters','parent_tasks','pending_permissions','source_info','sub_tasks', 'task_category', 'task_type', 'updated_at','updated_by','voters','workspace_ids','workspace_status','collaborators','contributors','num_parents','num_collaborators','num_contributors','my_index'].forEach((element) => {
        delete task[element]
    })
    return task;
}