export const autoTaskify = async (conv_id, commands, session_title) => {
    const result = await logFetchAJAX(getUrl('/api/tasks/autoTaskify'), {
        method: 'POST',
        body: { 
            commands: commands,
            session_title: session_title,
            conv_id: conv_id,
        }
    });
    
    await logUserActivity({
        "pageURL": getCurrentURL(),
        "human_friendly_message": `Convert a CLI / AI session into runbook - ${result.task.id} - ${session_title}`,
        "action": "autoTaskify",
        "referrer": document.referrer
    },true)

      
    return result;
}
